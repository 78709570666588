module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-152938448-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"crgwebsite","previews":true,"path":"/preview","pages":[{"type":"Profile","match":"/team/:uid","path":"/team-preview","component":"/opt/build/repo/src/templates/profile.js"},{"type":"News","match":"/news/:uid","path":"/news-preview","component":"/opt/build/repo/src/templates/post.js"},{"type":"Client_result","match":"/client-result/:uid","path":"/client-result-preview","component":"/opt/build/repo/src/templates/client-result.js"},{"type":"Legal","match":"/legal/:uid","path":"/legal-preview","component":"/opt/build/repo/src/templates/legal.js"},{"type":"Service","match":"/services/:uid","path":"/services-preview","component":"/opt/build/repo/src/templates/service.js"},{"type":"Insight","match":"/resources/:uid","path":"/resources-preview","component":"/opt/build/repo/src/templates/insight.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"carly-rian-group","short_name":"crg","start_url":"/","background_color":"#003057","theme_color":"#003057","display":"minimal-ui","icon":"src/images/favicon@2x.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb9333d5b75f21009ae585ed00123309"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
