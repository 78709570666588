exports.linkResolver = function linkResolver(doc) {
  if (doc.type === "insight") {
    return "/insights/" + doc.uid
  }
  if (doc.type === "insights") {
    return "/insights"
  }
  if (doc.type === "client_result") {
    return "/client-result/" + doc.uid
  }
  if (doc.type === "client_results") {
    return "/client-results"
  }
  if (doc.type === "news") {
    return "/news/" + doc.uid
  }
  if (doc.type === "about") {
    return "/about"
  }
  if (doc.type === "team") {
    return "/team"
  }
  if (doc.type === "profile") {
    return "/team/" + doc.uid
  }
  if (doc.type === "services") {
    return "/services"
  }
  if (doc.type === "culture") {
    return "/culture"
  }
  if (doc.type === "capabilities") {
    return "/capabilities"
  }
  if (doc.type === "homepage") {
    return "/"
  }
  // Route fallback
  return "/"
}
